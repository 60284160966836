/**
 * Styled button components (currently using Bootstrap 3 styles)
 */
import { Anchor, Link } from '@maternity/mun-router';
import classNames from 'classnames';
import * as React from 'react';

import { Variant } from './types';
// TODO: Add explicit dependency on Bootstrap

interface StyleProps {
  variant?: Variant | 'link';
  size?: 'lg' | 'sm' | 'xs'; // omit for default size
  block?: boolean;
}

export type ButtonProps<C extends React.ElementType> = React.ComponentProps<C> &
  StyleProps & {
    component?: C;
  };

/**
 * Renders a `<button>` tag by default, but the `component` prop allows another
 * component (or tag) to be used.
 *
 * For `<button>`, the `type` prop defaults to `button` instead of `submit`.
 */
export const Button = <C extends React.ElementType = 'button'>({
  component = 'button',
  variant = 'default',
  size,
  block,
  className,
  ...props
}: ButtonProps<C>) => {
  const classes = classNames(
    `btn btn-${variant}`,
    size && `btn-${size}`,
    block && 'btn-block',
    className,
  );
  if (component === 'button' && !props.type) {
    (props as React.ComponentProps<'button'>).type = 'button';
  }
  const Component = component;
  return <Component className={classes} {...props} />;
};

type AnchorButtonProps = StyleProps &
  React.ComponentProps<'a'> & {
    // Require a href
    href: string;
  };

/**
 * Renders an `<a>` tag as a styled button.
 *
 * TODO: Set `role="button"`?
 * TODO: Translate `disabled` attribute to class?
 */
export const AnchorButton = (props: AnchorButtonProps) => (
  <Button component={Anchor} {...props} />
);

type LinkButtonProps = StyleProps & React.ComponentProps<typeof Link>;

/**
 * Renders the Link component as a styled button.
 */
export const LinkButton = (props: LinkButtonProps) => (
  <Button component={Link} {...props} />
);

type OmniButtonProps =
  | AnchorButtonProps
  | LinkButtonProps
  // Disallow the `component` prop here
  | Omit<ButtonProps<'button'>, 'component'>;

/**
 * Infers which styled button component to render from the props given.
 */
export const OmniButton = (props: OmniButtonProps) => {
  if ('sref' in props) {
    return <LinkButton {...props} />;
  } else if ('href' in props) {
    return <AnchorButton {...props} />;
  }
  return <Button {...props} />;
};
