import { useForceUpdate, useIsMounted } from '@maternity/mun-cantrips';
import { DocsAsRefs } from '@maternity/mun-types';
import { injector } from '@maternity/ng-mun-linear';
import * as React from 'react';

import { goto } from './goto';

// TODO: Move this stuff to a different package? (e.g. a non-ng mun-doc)

/**
 * This interface serves as a registry that maps IO paths to their route
 * configuration. Packages that define mun-doc IO functions should include a
 * `global.d.ts` that augments this interface so that `getMunDocIO` and
 * `useMunDocLoader` can infer appropriate parameter and return value types.
 *
 * Example `global.d.ts`:
 * ```
 * import { Routes } from '@maternity/kerbin/app/js/types';
 * declare module '@maternity/mun-router/useMunDocLoader' {
 *   interface MunDocIORegistry {
 *     'libraryResourceIO.load': Routes['library_resource_load'];
 *   }
 * }
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MunDocIORegistry {}

/** The types of path params handled by mun-doc's `nontrivialBuildUrl` */
type PathParam = string | { uid: string };
/** These path params should be excluded since they are handled automatically */
type ExcludeParams = 'session_person_uid' | 'session_person_mode';
/** Infers a tuple of path params from the given path string */
type InferPathParams<Path extends string> =
  Path extends `${infer Prefix}<${infer Param}>${infer Suffix}`
    ? Param extends ExcludeParams
      ? InferPathParams<Suffix>
      : [PathParam, ...InferPathParams<Suffix>]
    : [];
/** The types of query args handled by mun-doc's `buildQueryString` */
// TODO: Restrict the value types?
export type QueryArgs =
  | Array<{ key: string; value: unknown }>
  | Record<string, unknown>;
/**
 * Try to determine the IO function parameters corresponding to the given IO
 * path string. Will evaluate to `unknown[]` if the IO path is not registered.
 */
type GetIOParams<IOPath extends string> = IOPath extends keyof MunDocIORegistry
  ? // Check if the endpoint expects to receive data
    MunDocIORegistry[IOPath]['message_in'] extends null
    ? [...InferPathParams<MunDocIORegistry[IOPath]['rule']>, QueryArgs?]
    : // TODO: Support last url param from data.uid and query args?
      [
        ...InferPathParams<MunDocIORegistry[IOPath]['rule']>,
        // Limit to doc refs for easier integration with mun-forms (it is
        // extremely rare for a `message_in` to traverse docs).
        DocsAsRefs<MunDocIORegistry[IOPath]['message_in']>,
      ]
  : unknown[];
/** Look up the message in schema corresponding to the given IO path string. */
export type GetIOMessageIn<IOPath extends keyof MunDocIORegistry> =
  // Limit to doc refs for easier integration with mun-forms (it is extremely
  // rare for a `message_in` to traverse docs).
  DocsAsRefs<MunDocIORegistry[IOPath]['message_in']>;
/**
 * Try to look up the message out schema corresponding to the given IO path
 * string. If the IO path is not registered, this will evaluate to the given
 * fallback type, which defaults to `unknown`.
 */
export type GetIOMessageOut<
  IOPath extends string,
  Fallback = unknown,
> = IOPath extends keyof MunDocIORegistry
  ? MunDocIORegistry[IOPath]['message_out']
  : Fallback;

interface MunDocIOFn<IOPath extends string = string> {
  cache?: Record<string, unknown>;
  (...params: GetIOParams<IOPath>): Promise<{
    data: GetIOMessageOut<IOPath, unknown>;
  }>;
  _buildUrl(...params: GetIOParams<IOPath>): string;
}

interface MkEndpointIOOptions {
  /** HTTP method. Default is inferred from the "mk" function used. */
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  /** Indicates if the request has a body. Defaults to true unless the method is
   * GET/HEAD. */
  hasRequestBody?: boolean;
  // TODO: type other options? (dictify, undictify, buildUrl)
}
type MkEndpointIO = (ep: string, options?: MkEndpointIOOptions) => MunDocIOFn;
type MkAccessIO = (
  ep: string,
  options?: MkEndpointIOOptions & {
    /** Pass false to disable response caching. */
    cache?: false | Record<string /* url */, unknown /* response */>;
  },
) => MunDocIOFn;
interface MunDocIOService {
  // TODO: type defaultIOOptions?
  mkBaseIO: MkEndpointIO;
  mkAccessIO: MkAccessIO;
  mkActionIO: MkEndpointIO;
  mkDocLister: MkAccessIO;
  mkDocLoader: MkAccessIO;
  mkDocSaver: MkEndpointIO;
  mkDocDeleter: MkEndpointIO;
}

type InitIOFn<IOPath extends string = string> = (
  munDocIO: MunDocIOService,
) => MunDocIOFn<IOPath>;
const ioInitRegistry = new Map<string /* io path */, InitIOFn>();
/**
 * Register a munDocIO function for use with `getMunDocIO`/`useMunDocLoader`
 * without defining a factory in angular. Do not use this if the IO function is
 * still used by angular code.
 *
 * The mapping of IO path to route config must still be added to the
 * `MunDocIORegistry` interface.
 */
export const registerMunDocIO = <IOPath extends string = string>(
  /** Dotted IO path that will be used to lookup/call the IO function */
  ioPath: IOPath,
  /** A function that is passed the angular munDocIO service, and should return
   * a configured IO function. */
  init: InitIOFn<IOPath>,
) => {
  if (ioInitRegistry.has(ioPath)) {
    throw new Error(`IO path already defined: ${ioPath}`);
  }
  ioInitRegistry.set(ioPath, init);
};

// Since the server request timeout is 60s, that should be the limit on how
// long a component can suspend between renders, and thus how long temporary
// cache entries are needed.
const TEMP_RETAIN_TIMEOUT = 60 * 1000;

type CacheEntryValue =
  | { promise: Promise<unknown> }
  | { error: unknown }
  | { data: unknown };

/**
 * An entry in the request cache.
 *
 * This code is heavily inspired by relay-experimental's (v7.1.0) QueryResource.
 */
class CacheEntry {
  value: CacheEntryValue;

  private retainCount = 0;
  private permanentlyRetained = false;
  private releaseTemporaryRetain?: () => void;

  constructor(
    /** Callback for loading data */
    private load: () => Promise<unknown>,
    /** Callback for deleting the cache entry */
    private deleteEntry: () => void,
  ) {
    // Start loading when constructed
    this.value = { promise: load() };
  }

  /** Triggers a reload of the data */
  reload(): Promise<unknown> {
    const promise = this.load();
    this.value = { promise };
    return promise;
  }

  private retain(): () => void {
    this.retainCount++;

    return () => {
      this.retainCount--;
      if (this.retainCount === 0) this.deleteEntry();
    };
  }

  /**
   * Call this during the render phase. Since we don't know if a render will
   * commit, we create a timeout to clean up the cache entry. Calling this
   * multiple times resets the timeout. Calling `permanentRetain` will clear
   * the timeout.
   */
  temporaryRetain(): void {
    if (this.permanentlyRetained) return;

    const dispose = this.retain();
    let releaseTimeout: number | undefined;
    const localReleaseTemporaryRetain = () => {
      clearTimeout(releaseTimeout);
      releaseTimeout = undefined;
      this.releaseTemporaryRetain = undefined;
      dispose();
    };
    // Without `window`, Typescript uses the node signature for `setTimeout`
    releaseTimeout = window.setTimeout(
      localReleaseTemporaryRetain,
      TEMP_RETAIN_TIMEOUT,
    );
    this.releaseTemporaryRetain?.();
    this.releaseTemporaryRetain = localReleaseTemporaryRetain;
  }

  /**
   * Call this during the commit phase (e.g. in `useEffect`), so the cache
   * entry is retained for the lifetime of the component.
   */
  permanentRetain(): () => void {
    const dispose = this.retain();
    this.releaseTemporaryRetain?.();
    this.permanentlyRetained = true;

    return () => {
      dispose();
      this.permanentlyRetained = false;
    };
  }
}

const ioCache = new Map<string /* io path */, MunDocIOFn>();
const reqCache = new Map<string /* url */, CacheEntry>();

/** Get an angular munDocIO function from a dotted path */
export const getMunDocIO = <IOPath extends string = string>(
  ioPath: IOPath,
): MunDocIOFn<IOPath> => {
  const cached = ioCache.get(ioPath);
  if (cached) return cached;
  let ioFn;
  const init = ioInitRegistry.get(ioPath);
  if (init) {
    ioFn = init(injector.get('munDocIO'));
  } else {
    const [serviceName, ...path] = ioPath.split('.');
    const service = injector.get(serviceName);
    ioFn = path.reduce((obj, prop) => obj[prop], service);
  }
  ioCache.set(ioPath, ioFn);
  return ioFn;
};

export const buildUrl = (ioPath: string, params: unknown[]): string => {
  const ioFn = getMunDocIO(ioPath);
  return ioFn._buildUrl(...params);
};

const getOrCreateCacheEntry = (
  ioPath: string,
  params: unknown[],
): CacheEntry => {
  const key = buildUrl(ioPath, params);
  const cached = reqCache.get(key);

  if (cached) {
    return cached;
  }

  const ioFn = getMunDocIO(ioPath);
  if (!ioFn.cache) {
    throw new Error(`Responses from "${ioPath}" are not cacheable.`);
  }
  const load = () => {
    const promise = ioFn(...params)
      .then(({ data }) => {
        const entry = reqCache.get(key);
        if (
          entry &&
          'promise' in entry.value &&
          entry.value.promise === promise
        ) {
          // Only record the data if the promise is in the cache
          entry.value = { data };
        }
        return data; // For munDocKeepalive
      })
      .catch((error: unknown) => {
        const entry = reqCache.get(key);
        if (
          entry &&
          'promise' in entry.value &&
          entry.value.promise === promise
        ) {
          // Only record the error if the promise is in the cache
          entry.value = { error };
        }
        // Swallow error to cancel the keepalive
      });
    return promise;
  };
  const value = new CacheEntry(load, () => reqCache.delete(key));
  reqCache.set(key, value);

  return value;
};

export class ResponseError extends Error {
  skipSentry = true;
  constructor(public response: any) {
    super(`Received error response: ${response.status}`);
  }
}

export const defaultProcessError = (error: any): never => {
  // Check if the "error" looks like a response
  if (!(error instanceof Error) && 'status' in error) {
    const { status } = error;
    // Trigger a redirect for 403/404
    if (status === 403 || status === 404) {
      goto('top.notfound', { status });
      // Throw a promise that will never resolve so the component stays
      // suspended until the redirect completes
      throw new Promise(() => {});
    }
    // Wrap other responses
    throw new ResponseError(error);
  }
  throw error;
};

/**
 * An object with a `read` method that returns loaded data or throws if loading
 * or if an error occurred.
 */
export interface MunDocResource<Data> {
  read(): Data;
}

/**
 * Manages loading data from an angular munDocIO service.
 *
 * The hook returns an object with a `read` method. Calling `read` returns the
 * loaded data or throws if loading or if an error occurred. As such, `read`
 * should generally only be called in a component's render function, and not in
 * effects or callbacks. Components that call `read` should be nested inside
 * `<Suspense>` and an error boundary in order to handle loading and error
 * states. If using multiple instances of this hook, avoid calling `read` until
 * after all of the hook calls so the requests can run in parallel.
 *
 * If the IO path type has been registered, the types of the parameters and
 * response data will be inferred. Otherwise, the `Data` type parameter should
 * be used to indicate the expected shape of data in successful responses.
 *
 * By default, data will be kept fresh with munDocKeepalive while the component
 * is mounted. Generally, this should be disabled when the data will be edited
 * in a form.
 *
 * ## Example (without types)
 * ```jsx
 * const BaseMessageView = ({ messageUid }) => {
 *   const memberResource = useMunDocLoader('practiceMemberIO.list');
 *   const messageResource = useMunDocLoader('messageIO.load', [messageUid]);
 *
 *   // Load members to populate author field
 *   memberResource.read();
 *
 *   const message = messageResource.read().item;
 *
 *   return (
 *     <div>
 *       <h4>{message.title}</h4>
 *       <p>From: {message.author.name.latest.long_name}</p>
 *       <p>{message.content}</p>
 *     </div>
 *   );
 * };
 *
 * export const MessageView = (...props) => (
 *   <React.Suspense fallback={<Spinner />}>
 *     <ErrorBoundary>
 *       <BaseMessageView {...props} />
 *     </ErrorBoundary>
 *   </React.Suspense>
 * );
 * ```
 */
export const useMunDocLoader = <Data, IOPath extends string = string>(
  /* The dotted path for looking up a munDocIO function */
  ioPath: IOPath,
  /* Optional array of parameters for the IO function */
  params: GetIOParams<IOPath> = [] as any,
  /* If true (default), loaded data will be kept fresh with munDocKeepalive. */
  keepalive: boolean = true,
  /* Optional function for processing errors before throwing them */
  processError = defaultProcessError,
): MunDocResource<GetIOMessageOut<IOPath, Data>> => {
  const isMounted = useIsMounted();
  const forceUpdate = useForceUpdate();

  const entry = getOrCreateCacheEntry(ioPath, params);

  entry.temporaryRetain();

  React.useEffect(() => entry.permanentRetain(), [entry]);

  const data = 'data' in entry.value ? entry.value.data : null;
  // Set up keepalive if enabled and have data
  React.useEffect(() => {
    if (!keepalive || !data) return;
    const munDocKeepalive = injector.get('munDocKeepalive');
    return munDocKeepalive(data, () => {
      if (isMounted()) {
        const promise = entry.reload();
        forceUpdate();
        return promise;
      }
    });
  }, [keepalive, entry, isMounted, forceUpdate, data]);

  // Return an object with a `read` method (`MunDocResource`) so components can
  // use multiple instances of this hook without creating a request waterfall.
  return React.useMemo(
    () => ({
      read() {
        const { value } = entry;
        if ('promise' in value) {
          throw value.promise;
        } else if ('error' in value) {
          return processError(value.error);
        } else {
          return value.data as GetIOMessageOut<IOPath, Data>;
        }
      },
    }),
    [entry, processError],
  );
};

/**
 * Call to prefetch data for `useMunDocLoader` (e.g. from a parent component or
 * the router).
 */
export const munDocPrefetch = (ioPath: string, params: unknown[] = []) => {
  getOrCreateCacheEntry(ioPath, params);
};
