/** Validation error with arbitrary context data. */
export interface ValidationErrorCtx {
  errId: string;
  // TODO: Is `unknown` too cumbersome here?
  [k: string]: unknown;
}
/**
 * Validation errors are either a bare error id string or an object containing
 * the error id string and arbitrary keys for context data
 */
export type ValidationError = string | ValidationErrorCtx;
/**
 * A Validator is a function that take a form value and returns a validation
 * error if the value is invalid
 */
export type Validator = (value: unknown) => ValidationError | undefined;
/**
 * Error messages are either a bare string or a function that takes an error
 * context and returns a string
 */
type ErrorMessage = string | ((ctx?: ValidationErrorCtx) => string);
/** Mapping of error id strings to error messages */
export type ErrorMessageMap = Partial<Record<string, ErrorMessage>>;

/** Normalizes validation errors as the object form to simplify handling */
export const normalizeError = (err: ValidationError): ValidationErrorCtx =>
  typeof err === 'string' ? { errId: err } : err;

/** Global for default error messages */
const defaultErrorMessages: ErrorMessageMap = {};

/** Registers a default error message for an error id */
export const setDefaultErrorMessage = (
  errId: string,
  message: ErrorMessage,
) => {
  // TODO: Warn if message already set for errId?
  defaultErrorMessages[errId] = message;
};

/** Returns the error message (string) for the given validation error. */
export const getErrorMessage = (
  err: ValidationErrorCtx,
  messageMap: ErrorMessageMap = {},
): string | undefined => {
  const message = messageMap[err.errId] || defaultErrorMessages[err.errId];
  return typeof message === 'function' ? message(err) : message;
};

// == Server-side validation (Travesty Invalid) handling ==

interface SingleInvalid {
  err_id: string;
  desc?: string;
  extra?: Record<string, any>;
}
interface Invalid {
  // The `_self` type is incompatible with the index signature, so we use a
  // cast where it is accessed.
  // _self: SingleInvalid[];
  [k: string]: Invalid;
}

interface ErrorResponse {
  status: 400 | 403;
  data: {
    invalid?: Invalid;
  };
}

/** Returns true if the argument looks like a 400/403 error response.  */
export const isErrorResponse = (err: unknown): err is ErrorResponse => {
  if (
    err &&
    typeof err === 'object' &&
    !(err instanceof Error) &&
    'status' in err
  ) {
    const status = (err as any).status;
    return status === 400 || status === 403;
  }
  return false;
};

/** Convert a serialized Invalid to a flat map of ValidationErrors */
export const convertInvalid = (invalid: Invalid, path = '') => {
  const result: Record<string, ValidationErrorCtx[]> = {};
  Object.entries(invalid).forEach(([k, v]) => {
    if (k === '_self') {
      if (!v) return;
      result[path] = (v as unknown as SingleInvalid[]).map(
        ({ err_id, desc, extra = {} }) => ({ errId: err_id, desc, ...extra }),
      );
    } else {
      const newPath = path === '' ? k : `${path}.${k}`;
      Object.assign(result, convertInvalid(v, newPath));
    }
  });
  return result;
};
