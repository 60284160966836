import { useMemo, useState } from 'react';

type RestParameters<
  F extends (...args: any[]) => any,
  Args extends any[],
> = F extends (...args: [...Args, ...infer Rest]) => any ? Rest : never;

interface Crash {
  set(error: any): void;

  call<F extends (...args: any[]) => PromiseLike<any>>(
    f: F,
    ...args: Parameters<F>
  ): Promise<ReturnType<F>>;

  bind<
    F extends (...args: [...PartialArgs, ...any[]]) => PromiseLike<any>,
    PartialArgs extends any[],
  >(
    f: F,
    ...partialArgs: PartialArgs
  ): (...restArgs: RestParameters<F, PartialArgs>) => Promise<ReturnType<F>>;
}

/** Connect async exceptions to the component tree. */
export function useCrash(): Crash {
  const [crash, setCrash] = useState<{ error: any }>();

  if (crash) throw crash.error;

  return useMemo(
    () => ({
      /** Set the error for the component.
       *
       * @param error The error to set for the component. */
      set(error: any) {
        setCrash({ error });
      },

      /** Call an async function, delivering any resulting exception to the
       * component.
       *
       * @param f The async function to call.
       * @param args Arguments to be passed to f.
       * @returns Whatever f(...args) returns.
       */
      async call<F extends (...args: any[]) => PromiseLike<any>>(
        f: F,
        ...args: Parameters<F>
      ) {
        try {
          return await f(...args);
        } catch (error) {
          setCrash({ error });
          throw error;
        }
      },

      /** Bind an async function so any resulting exceptions will be delivered to
       * the component.
       *
       * @param f The function being bound.
       * @param partialArgs Arguments to be passed to f.
       * @returns A function that calls f with partial and immediate arguments
       * and delivers any resulting exception to the component.
       */
      bind<
        F extends (...args: [...PartialArgs, ...any[]]) => PromiseLike<any>,
        PartialArgs extends any[],
      >(f: F, ...partialArgs: PartialArgs) {
        return async (
          ...restArgs: RestParameters<F, PartialArgs>
        ): Promise<ReturnType<F>> => {
          try {
            return await f(...partialArgs, ...restArgs);
          } catch (error) {
            setCrash({ error });
            throw error;
          }
        };
      },
    }),
    [],
  );
}
