import moment from 'moment';

const formatDate =
  (format: string, parseFormat?: string | string[]) =>
  (date?: Date | string | moment.Moment | null) =>
    date ? moment(date, parseFormat).format(format) : '';

const dateFormat = 'MM/DD/YYYY';
const timeFormat = 'h:mm A';
const modelTimeFormat = 'HH:mm:ss.SSS';

export const mnStandardDate = formatDate(dateFormat);
export const mnStandardFullDate = formatDate('dddd, DD MMM YYYY');
export const mnStandardTime = formatDate(timeFormat, modelTimeFormat);
export const mnStandardDatetime = formatDate(`${dateFormat} ${timeFormat}`);
