// Safari < 10 does not support Intl.NumberFormat
export const currency: (number: number) => string =
  typeof Intl === 'object' && Intl && typeof Intl.NumberFormat === 'function'
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format
    : (number) => {
        const sign = number < 0 ? '-' : '';
        const [integer, fraction] = Math.abs(number).toFixed(2).split('.');
        return `${sign}$${Number(integer).toLocaleString()}.${fraction}`;
      };
