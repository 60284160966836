import classNames from 'classnames';
import * as React from 'react';

import silhouetteUrl from '../images/icons/silhouette.png';
import { kerbin_Person } from './types';

interface Props {
  person?: Pick<kerbin_Person, 'avatar'>;
  size?: 'default' | 'sm' | 'md' | 'lg';
  className?: string;
}

/** Renders the avatar image for the given person. */
export const Avatar = ({ person, size = 'default', className }: Props) => (
  <img
    className={classNames(
      'kerbin-avatar',
      'img-circle',
      `avatar-${size}`,
      className,
    )}
    src={person?.avatar?.$url || silhouetteUrl}
  />
);
