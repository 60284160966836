// This file prepares a foundation for the main application code by loading
// polyfills, supplying configuration, etc.

// Load polyfills first
import '@babel/polyfill';
import 'details-element-polyfill'; // Only needed by IE/Edge

import env from '@maternity/mun-env';
import Raven from 'raven-js';

import '@maternity/vendor-modernizr';


const KERBIN_ENV = window.KERBIN_ENV || {};


// Configure raven
const options = {
  maxMessageLength: 2000,
  ignoreErrors: [
    // Ignore errors in code injected by Chrome Mobile on iOS
    '__gCrWeb.',
  ],
};
// Expose raven extra/tags so they can be extended within the app.
const tags = options.tags = window._ravenTags = {};
options.extra = window._ravenExtra = {};

// We can bin installations by base URI, but we need build host to distinguish localhosts.
tags.base_uri = document.baseURI;
tags.build_host = process.env.BUILDHOST;

// Not sure if the UI can handle a lot of this, we might want to limit this to releases only.
tags.git_describe = process.env.GIT_VERSION;

const sentry_dsn = KERBIN_ENV.SENTRY_JS_DSN;
if (sentry_dsn) Raven.config(sentry_dsn, options).install();


// Configure mun-env
env.SESSION_COOKIE_NAME = KERBIN_ENV.SESSION_COOKIE_NAME;
env.SESSION_TIMEOUT_COOKIE = KERBIN_ENV.SESSION_TIMEOUT_COOKIE;
env.SESSION_TIMEOUT_WARNING_COOKIE = KERBIN_ENV.SESSION_TIMEOUT_WARNING_COOKIE;
env.STERLING_SERVICE_LOCATION = process.env.STERLING_SERVICE_LOCATION;

env.STATIC_ROOT = process.env.STATIC_ROOT;

env.MAX_BODY_SIZE = KERBIN_ENV.MAX_BODY_SIZE;

env.KERBOL_HOME = KERBIN_ENV.KERBOL_HOME;

env.CUSTOM_BRAND = KERBIN_ENV.CUSTOM_BRAND;
