import classNames from 'classnames';
import * as React from 'react';

interface Props {
  /** If present, add the given content before the wrapped input. */
  prepend?: React.ReactNode;
  /** If present, add the given button before the wrapped input. */
  prependButton?: React.ReactNode;
  /** If present, add the given content after the wrapped input. */
  append?: React.ReactNode;
  /** If present, add the given button after the wrapped input. */
  appendButton?: React.ReactNode;
  /** Optional class to add to the `.input-group` container. */
  className?: string;
  /** An `Input` control to wrap. */
  children?: React.ReactNode;
}

/**
 * Wraps an `Input` and adds content before and/or after it.
 *
 * The `Input` should be passed via `children`.
 */
export const InputGroup = ({
  prepend,
  prependButton,
  append,
  appendButton,
  className,
  children,
}: Props) => (
  <div className={classNames(className, 'input-group')}>
    {prependButton && <span className="input-group-btn">{prependButton}</span>}
    {prepend && <span className="input-group-addon">{prepend}</span>}
    {children}
    {append && <span className="input-group-addon">{append}</span>}
    {appendButton && <span className="input-group-btn">{appendButton}</span>}
  </div>
);
